<template>
  <div>
    <b-navbar toggleable="sm" type="light" variant="light" class="shadow mb-4">
      <b-container>
        <b-navbar-brand to="/">
          <img
            class="img-responsive"
            src="@/assets/logo_estia.png"
            alt="EstiaLabs"
        />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              link-classes="text-body mr-2"
              :to="{ name: 'index' }"
              exact-active-class="active"
              >Home</b-nav-item
            >
            <b-nav-item v-if="!$auth.loggedIn || ($auth.user.paymentType !== 'ELNM' && ($auth.user.grantedAuthorities.includes('PARENT') || $auth.user.grantedAuthorities.includes('STUDENT')))"
              link-classes="text-body mr-2"
              :to="{ name: 'members-area' }"
              exact-active-class="active"
              >Members {{ $auth.loggedIn ? 'Area' : 'Login' }}</b-nav-item
            >
            <template v-if="$auth.loggedIn && $auth.user.isConsultant">
              <b-nav-item 
                link-classes="text-body mr-2"
                :to="{ name: 'iris' }"
                exact-active-class="active"
                >IRIS</b-nav-item
              >
              <b-nav-item
                link-classes="text-body mr-2"
                :to="{ name: 'iris-presentations' }"
                exact-active-class="active"
                >Presentations</b-nav-item
              >
            </template>
            <b-button v-if="!$auth.loggedIn || $auth.user.grantedAuthorities.includes('PARENT')"
              :to="{ name: `${ $auth.loggedIn ? 'get' : 'book' }-teaching-sessions` }"
              exact-active-class="active"
              variant="primary"
              >Book Teaching Sessions</b-button
            >
            <b-button v-if="$auth.loggedIn"
              :to="{ name: 'logout' }"
              exact-active-class="active"
              variant="primary"
              class="logoutBtn"
              v-b-tooltip.hover
              title="Logout of EstiaLabs"
              >Logout</b-button
            >
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
};
</script>

<style scoped src="@/assets/css/home_bulma.min.css"></style>